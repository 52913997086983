<template>
  <b-overlay :show="isLoading" rounded="lg" opacity="0.6" spinner-variant="primary">
    <template #overlay>
      <div class="d-flex align-items-center">
        <b-spinner small type="grow" variant="secondary"></b-spinner>
        <b-spinner type="grow" variant="dark"></b-spinner>
        <b-spinner small type="grow" variant="secondary"></b-spinner>
      </div>
    </template>
    <b-row>
      <b-col class="mt-4">
        <i class="fa fa-backward"></i>
        <router-link :to="{ name: MagazinePath.name }">
          Magazines
        </router-link>
      </b-col>
    </b-row>
    <b-form @submit="onSubmit" @reset="reset" v-if="show" id="form-new">
      <b-card header="Create New Magazine" header-bg-variant="primary" header-tag="h5" header-class="font-weight-bold">
        <b-row>
          <b-col md="6">
            <b-form-group label="Brand">
              <multiselect v-model="form.brand" label="name" track-by="name" placeholder="Enter Brand"
                open-direction="bottom" required="required" :options="brandSearchOptions" :searchable="true"
                :loading="isBrandSearch" :close-on-select="true" :options-limit="10" :multiple="false"
                :allow-empty="false" @search-change="brandFind" @select="brandSelected">
              </multiselect>
            </b-form-group>
          </b-col>
          <b-col md="6"></b-col>
          <b-col md="6" lg="4">
            <b-form-group id="input-display" label="Display Name (Item’s Title)" label-for="display">
              <b-form-input id="display" v-model="form.name" type="text" placeholder="Enter Display Name"
                required></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6" lg="4">
            <b-form-group id="input-issue" label="Issue Number" label-for="issue">
              <b-form-input id="issue" :disabled="userData.role_id === 5"  v-model="form.issue_number" type="text" placeholder="Enter Issue Number"
                @input="onChangeIssueNumber" required></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6" lg="4">
            <b-form-group id="input-code" label="Edition Code" label-for="code">
              <b-form-input id="code" v-model="form.edition_code" type="text" placeholder="Enter Edition Code"
                required></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6" lg="4">
            <label>Languages</label>
            <multiselect placeholder="Enter languages" open-direction="bottom" v-model="form.languages" label="name"
              track-by="name" :custom-label="nameWithISO" :options="languagesSearchOptions" :searchable="true"
              :close-on-select="true" :options-limit="10" :multiple="true" required>
            </multiselect>
          </b-col>
          <b-col md="6" lg="4">
            <label>Countries</label>
            <multiselect placeholder="Enter Countries" open-direction="bottom" v-model="form.countries" label="name"
              track-by="name" :custom-label="nameWithISO" :options="countriesSearchOptions" :loading="isCountriesSearch"
              :searchable="true" :close-on-select="true" :options-limit="10" :multiple="true">
            </multiselect>
          </b-col>
          <b-col md="6" lg="4">
            <label>Categories</label>
            <multiselect v-model="form.categories" label="name" track-by="name" placeholder="Enter Categories"
              open-direction="bottom" :options="categoriesSearchOptions" :loading="isCategoriesSearch"
              :searchable="true" :close-on-select="true" :options-limit="10" :multiple="true"
              @search-change="categoriesFind">
            </multiselect>
          </b-col>
        </b-row>
      </b-card>
      <b-card header="Detail" header-bg-variant="primary" header-tag="h5" header-class="font-weight-bold">
        <b-row>
          <b-col md="6">
            <DatetimeCustom
              :label="'Release Date (GMT ' + timezoneString() + ')'"
              v-model="form.release_date"
            ></DatetimeCustom>
          </b-col>
          <b-col md="6">
            <DatetimeCustom
              :label="'Release Schedule (GMT ' + timezoneString() + ')'"
              v-model="form.schedule_date"
            ></DatetimeCustom>
          </b-col>
          <b-col md="6">
            <b-form-group id="input-currency" label="Printed Price Currency" label-for="currency">
              <b-form-select id="currency" v-model="form.currency" :options="currencys" required></b-form-select>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group id="input-price" label="Printed Price" label-for="price">
              <b-form-input id="price" v-model="form.price" type="number" step="any" placeholder="99000 (no sparator)"
                required>
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group id="input-content" label="Content Type" label-for="content">
              <b-form-select id="content" v-model="form.content" :options="contents" required></b-form-select>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group id="input-parental" label="Parental Control" label-for="parental">
              <b-form-select id="parental" v-model="form.parental" :options="parentals" required></b-form-select>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group id="input-distribution" label="Distribution Countries" label-for="distribution">
              <b-form-select id="distribution" v-model="form.distribution" :options="distributionSearchOptions" disabled>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group id="input-reading" label="Reading Direction" label-for="reading">
              <b-form-select id="reading" v-model="form.reading" :options="readings" required></b-form-select>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <div class="pt-2">
              <b-form-checkbox v-model="form.is_active" :value="true" :unchecked-value="false">
                Active
              </b-form-checkbox>
              <b-form-checkbox v-model="form.is_internal_content" :value="true" :unchecked-value="false"
                v-if="!isProfileVendor"
              > Internal Content
              </b-form-checkbox>
            </div>
          </b-col>
        </b-row>
      </b-card>
      <b-card header="Optional" header-bg-variant="primary" header-tag="h5" header-class="font-weight-bold">
        <b-row>
          <b-col>
            <b-form-group id="input-description" label="Description" label-for="description">
              <b-form-textarea id="description" v-model="form.description" placeholder="Enter something..."
                rows="5"></b-form-textarea>
            </b-form-group>
            <b-button variant="outline-primary" type="reset">Reset Form</b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-form>
    <b-button class="fix-bot-right" size="lg" variant="primary" type="submit" form="form-new" :hidden="isLoading">
      <i class="fa fa-save"> Save</i>
    </b-button>
  </b-overlay>
</template>

<script>
import moment from "moment";
import { mapState, mapActions } from "vuex";
import { MagazinePath } from "../../router/content";
import constant from "../../store/constant";
import DatetimeCustom from "../../components/form/Datetime.vue"

const { ITEM_TYPES } = constant;
delete constant.CONTENT_TYPE.AUDIO;
const CONTENT_TYPE = Object.values(constant.CONTENT_TYPE);
const READING_DIRECTION = Object.entries(constant.READING_DIRECTION).map((item) => {
  return {
    text: item[0],
    value: item[1],
  }
});
const PARENTAL_CONTROL = Object.entries(constant.PARENTAL_LEVEL_TYPE).map((item) => {
  return {
    text: item[0],
    value: item[1],
  }
});
const PRINTED_PRICE = Object.values(constant.PRINTED_PRICE_ITEM);

export default {
  name: "newMagazine",
  components: {
    DatetimeCustom
  },
  data() {
    return {
      MagazinePath,
      userData : JSON.parse(localStorage.getItem('session_auth')),
      form: {
        name: "",
        issue_number: "",
        edition_code: "",
        release_date: moment().toString(),
        schedule_date: moment().toString(),
        price: "",
        description: "",
        brand: [],
        languages: [{ iso: 'IND', name: 'Indonesia' }],
        countries: [{ iso: 'ID', name: 'Indonesia' }],
        categories: [],
        currency: 'IDR',
        content: 'PDF',
        parental: 1,
        distribution: null,
        reading: 'left-to-right',
        checked: [],
        is_active: true,
        is_internal_content: false,
      },
      currencys: [
        { text: "Select Currency", value: null }, ...PRINTED_PRICE],
      contents: [{ text: "Select Content", value: null }, ...CONTENT_TYPE],
      parentals: [{ text: "Select Parental Type", value: null }, ...PARENTAL_CONTROL],
      readings: [
        { text: "Select Direction", value: null }, ...READING_DIRECTION
      ],
      distributionSearchOptions: [
        { text: "Select Distributin Countries", value: null }
      ],
      show: true,
      brandSearchOptions: [],
      categoriesSearchOptions: [],
      countriesSearchOptions: [],
      languagesSearchOptions: Object.entries(constant.LANGUAGE_CHOICES).map((item) => ({
        iso: item[0].toUpperCase(),
        name: item[1]
      })),
      isBrandSearch: false,
      isCategoriesSearch: false,
      isCountriesSearch: false,
      debounce: null,
    };
  },
  computed: {
    ...mapState({
      isError: (state) => state.magazine.isError,
      isLoading: (state) => state.magazine.isLoading,
      errorMessage: (state) => state.magazine.errorMessage,
      successMessage: (state) => state.magazine.successMessage,
      item: (state) => state.magazine.item,
      isProfileVendor: (state) => state.profile?.vendor_id,
    }),
  },
  watch: {
    errorMessage: function () {
      if (!this.errorMessage) return;
      this.messageAlert('error', this.errorMessage, 5000);
    },
    successMessage: function () {
      if (!this.successMessage) return;
      this.messageAlert('success', this.successMessage);
      this.$router.push(MagazinePath);
    },
  },
  created() {
    this.setCountriesOptions();
    this.brandFind('');
    this.fetchProfile();
  },
  methods: {
    ...mapActions('profile', ['fetchProfile']),
    ...mapActions('brands', ['searchBrands', 'getCategoriesByBrandIdAdapter']),
    ...mapActions('magazine', ['postNewMagazine']),
    ...mapActions('items', ['searchCategoriesV2', 'getCountries', 'generateEditionCodeBookMagazine']),
    onSubmit(event) {
      event.preventDefault();
      if (!this.validateForm()) return;

      const data = this.form;
      const payload = {
        name: data.name,
        edition_code: data.edition_code,
        content_type: data.content.toLowerCase(),
        printed_price: data.price,
        brand_id: data.brand.id,
        categories_id: data.categories.map(item => item.id),
        release_date: data.release_date,
        release_schedule: data.schedule_date,
        description: data.description,
        printed_currency_code: data.currency,
        issue_number: data.issue_number,
        is_active: data.is_active,
        is_internal_content: data.is_internal_content,
        parentalcontrol_id: data.parental,
        reading_direction: data.reading,
        item_distribution_country_group_id: data.distribution,
        countries: data.countries.map(item => item.iso.toLowerCase()),
        languages: data.languages.map(item => item.iso.toLowerCase()),
      }
      this.postNewMagazine(payload);
    },

    validateForm() {
      this.form.edition_code = this.form?.edition_code.trim();

      if (this.form?.edition_code?.includes(' ')) {
        this.$swal('Edition Code!!', 'cannot contain spaces', 'warning');
        return false;
      }

      return true;
    },

    brandFind(query) {
      this.isBrandSearch = true;
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        let payload = {
          name: query,
          type: constant.ITEM_TYPES.MAGAZINE.toLowerCase(),
        };
        this.searchBrands(payload).then((response) => {
          this.brandSearchOptions = response.data.data.rows;
          this.isBrandSearch = false;
        }).catch(() => {
          this.isBrandSearch = false;
        });
      }, 600);
    },

    categoriesFind(query) {
      if (!query) return;

      this.isCategoriesSearch = true;
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.searchCategoriesV2({
          name: query,
          item_type: ITEM_TYPES.MAGAZINE.toLowerCase(),
          is_active: 1,
        })
          .then((response) => {
            this.categoriesSearchOptions = response.data.data.rows;
            this.isCategoriesSearch = false;
          })
          .catch(() => {
            this.isCategoriesSearch = false;
          });
      }, 600);
    },

    setCountriesOptions() {
      this.isCountriesSearch = true;
      this.getCountries()
        .then((response) => {
          this.countriesSearchOptions = response.data.data.rows;
          this.isCountriesSearch = false;
        })
        .catch(() => {
          this.isCountriesSearch = false;
        });
    },

    async brandSelected(data) {
      this.form.languages = this.languagesSearchOptions
        .filter(
          item => data.default_languages.includes(item.iso.toLowerCase())
        );
      this.form.countries = this.countriesSearchOptions
        .filter(
          item => data.default_countries.includes(item.iso.toLowerCase())
        );
      this.form.categories = data.default_categories;
      this.form.name = data.name + ' / ED';
      this.form.issue_number = null;
      this.form.edition_code = await this.generateEditionCodeBookMagazine(data);
      const defaultCategories = await this.getCategoriesByBrandIdAdapter({ id: data.id });
      this.form.categories = defaultCategories?.map(val => ({
        name: val.categoriesName,
        id: val.categoryId,
      }));
    },

    authorSelected(data) {
      const issue_number = data.map(item => item.name).join(', ');
      this.form.issue_number = issue_number;
    },

    onChangeIssueNumber(data) {
      const displayName = this.form?.brand?.name;
      if (!displayName) return;
      this.form.name = `${displayName} / ${data}`;
    },

    messageAlert(icon, title, timer = 3000) {
      this.$swal({
        toast: "true",
        position: "top-end",
        icon,
        title,
        showConfirmButton: true,
        timer,
        timerProgressBar: true,
      });
    },

    nameWithISO({ name, iso }) {
      return `${iso} - ${name}`
    },

    reset() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
  }
};
</script>

<style lang="css">
.fix-bot-right {
  position: fixed;
  bottom: 20px;
  right: 20px;
  box-shadow: 0 0 10px white;
}
</style>
